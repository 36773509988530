import React from "react";

import { Link } from "gatsby";
import { Menu } from "antd";

import {
  RocketOutlined,
  TeamOutlined,
  MinusOutlined,
  ShopOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";

export const ReferenciasMenu = ({ activePage }) => (
  <div id="referenciasMenu" className="referenciasMenuWrapper">
    <Menu
      onClick={() => console.log("whatever")}
      selectedKeys={[activePage]}
      mode="inline"
    >
      <Menu.Item
        key="exterior"
        className="thermoProtect exterior"
        icon={<ShopOutlined />}
      >
        <Link to="/referencias/exterior/"> Exterior</Link>
      </Menu.Item>

      <Menu.Item
        key="interiores"
        className="interior thermoPlus"
        icon={<TeamOutlined />}
      >
        <Link to="/referencias/interior/"> Interior</Link>
      </Menu.Item>

      <Menu.Item
        key="cubiertas"
        className="cubiertas thermoActive"
        icon={<MinusOutlined />}
      >
        <Link to="/referencias/cubiertas/"> Cubiertas/Tejados</Link>
      </Menu.Item>
      {/*
       <Menu.Item
        key="industry"
        className="industrial industrySpecial"
        icon={<RocketOutlined />}
      >
        <Link to="/referencias/industrial/"> Industrial</Link>
      </Menu.Item>
      */}

      <Menu.Item
        key="humedades"
        className="humedades thermoVital"
        icon={<BgColorsOutlined />}
      >
        <Link to="/referencias/humedades/"> Humedades</Link>
      </Menu.Item>
    </Menu>
  </div>
);

export default ReferenciasMenu;
