import React from "react";

import ReferenciasHumedades from "src/components/referencias/Humedades";
import { LayoutReferencia } from "src/layout/LayoutReferencia";

const ReferenciasHumedadesPage = (props) => (
  <LayoutReferencia
    {...{
      activePage: "humedades",
      pageTitle: "Referencias de ClimateCoating para Humedades",
      pageTitleJSX: (
        <span>
          <span className="mainTitle colored thermoVital">
            Protección y mejora de la Salubridad:
          </span>
          <span className="secondaryTitle">
            Referencias en España de ClimateCoating para Humedades
          </span>
        </span>
      ),

      pageName: "Humedades",
      pageDescription:
        "Referencias en España de ClimateCoating para Humedades: ThermoPlus y ThermoVital",
    }}
  >
    <ReferenciasHumedades
      {...{
        xs: 1,
        sm: 2,
        xl: 4,
        xxl: 4,
      }}
    />
  </LayoutReferencia>
);

export default ReferenciasHumedadesPage;
