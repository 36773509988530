import React from "react";

import { Content } from "antd/lib/layout/layout";
import { LayoutGeneral } from "src/layout/LayoutGeneral";

import ReferenciasMenu from "src/menus/referencias";

export const LayoutReferencia = ({
  activePage,
  pageTitleJSX,
  pageTitle,
  pageName,
  pageDescription,
  children,
}) => {
  return (
    <LayoutGeneral
      {...{ activePage, pageTitleJSX, pageTitle, pageName, pageDescription }}
    >
      <Content className="page productReference">
        <div className="referenciasMenu">
          <ReferenciasMenu {...{ activePage }} />
        </div>
        <div className="referenciasContent">{children} </div>
      </Content>
    </LayoutGeneral>
  );
};
